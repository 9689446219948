<template>
  <div>
    <validation-observer
      ref="bonusForm"
      #default="{invalid}"
    >
      <p v-if="!isAccepted">
        {{ $t('bonus-module.code-from.desc') }}
      </p>
      <b-row
        v-if="!isAccepted"
        class="mt-2 font-weight-bold"
      >
        <b-col
          cols="12"
          sm="6"
        >
          <b-form-group>
            <validation-provider
              #default="{ errors }"
              :name="$t('bonus_code')"
              vid="code"
              rules="required"
            >
              <b-form-input
                v-model="bonusCode"
                :placeholder="$t('bonus-module.code-from.bonus-code')"
                :disabled="isAccepted"
              />
            </validation-provider>
          </b-form-group>
        </b-col>
        <b-col
          cols="12"
          sm="6"
        >
          <b-button
            :disabled="submitLoading || invalid || isAccepted"
            variant="primary"
            style="width: 150px;"
            @click="validateCode(bonusCode)"
          >
            <b-spinner
              v-if="submitLoading"
              label="Loading..."
              small
            />
            <span v-if="!submitLoading">
              {{ $t('bonus-module.code-from.register_code') }}
            </span>
          </b-button>
        </b-col>
      </b-row>
    </validation-observer>
    <b-alert
      v-for="item in items"
      :key="item.description"
      :variant="item.color"
      :show="item.show"
      class="mt-1"
    >
      <div
        class="alert-body p-2 d-flex justify-content-between"
        style="gap: 5px;"
      >
        <div>
          <p>
            {{ $t(item.description) }}
          </p>
          <span v-if="isAccepted">{{ bonusCode }}</span>
        </div>
        <b-button
          variant="text"
          @click="removeCode"
        >
          <b-icon-x />
        </b-button>
      </div>
    </b-alert>
  </div>
</template>
<script>
import {
  BRow,
  BCol,
  BButton,
  BFormGroup,
  BFormInput,
  BAlert,
  BIconX,
} from 'bootstrap-vue'
import BonusApis from '../../service/apis'

const bonus = new BonusApis()
export default {
  components: {
    BRow,
    BCol,
    BButton,
    BFormGroup,
    BFormInput,
    BAlert,
    BIconX,
  },

  data() {
    return {
      bonusCode: '',
      submitLoading: false,
      isAccepted: false,
      items:
         [
           {
             key: 'verfyFailed',
             color: 'danger',
             description: 'bonus-module.code-from.bonus-alert-reject-desc',
             show: false,
           },
           {
             key: 'verfySuccess',
             color: 'success',
             description: 'bonus-module.code-from.bonus-alert-success-desc',
             show: false,
           },
         ],
    }
  },
  computed: {

  },
  methods: {
    validateCode(code) {
      this.submitLoading = true
      bonus.verfyCode({ code }).then(res => {
        this.showMessage('verfySuccess')
        this.onCodeAccepted()
        this.isAccepted = true
      }).catch(error => {
        this.showMessage('verfyFailed')
        this.onCodeRejected()
        this.isAccepted = false
      }).finally(() => {
        this.submitLoading = false
      })
    },
    onCodeAccepted() {
      this.$emit('onAccepted', this.bonusCode)
    },
    onCodeRejected() {
      this.$emit('onRejected', this.bonusCode)
    },
    showMessage(key) {
      this.items.forEach((item, index) => {
        this.items[index].show = false
        if (item.key === key) {
          this.items[index].show = true
        }
      })
    },
    removeCode() {
      this.showMessage()
      this.isAccepted = false
      this.bonusCode = ''
    },
  },
}
</script>
